.team-page {
    font-family: 'Josefin Sans', sans-serif;
    color: rgba(193, 39, 54, 1);
}

.team-header {
    text-align: center;
    font-weight: bold;
    font-size: 45px;
    margin-top: 30px;
    margin-bottom: 25px;
    z-index: 100;
}

.team-subtitle {
    font-weight: bold;
    font-size: 35px;
    text-align: center;
    z-index: 100;
    margin-bottom: 10px;
    position: relative;
}

.cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-right: 15%;
    margin-left: 15%;
}

.member-type-container {
    margin-bottom: 40px;
}

#equipeImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 520px;
}